import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyAfplpImDFnTjkLwTzE6vVY3-E56NSaQvA",
  authDomain: "ambey-nexus.firebaseapp.com",
  projectId: "ambey-nexus",
  storageBucket: "ambey-nexus.firebasestorage.app",
  messagingSenderId: "781613123629",
  appId: "1:781613123629:web:16a18ca591435681c0d0a4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);