import React from "react";
import { useParams, Link } from "react-router-dom";
import { blogData } from "./BlogData"; // Import blog data

const BlogDetailPage = () => {
  const { id } = useParams();
  const blog = blogData.find((post) => post.id === parseInt(id));

  if (!blog) {
    return <div className="text-center mt-12 text-xl">Blog not found!</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-6">
      <div className="container mx-auto">
        <Link
          to="/blogs"
          className="inline-block mb-6 text-indigo-600 hover:underline"
        >
          ← Back to Blogs
        </Link>
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <img
            src={blog.image}
            alt={blog.title}
            className="w-full h-64 object-cover"
          />
          <div className="p-6">
            <h1 className="text-4xl font-bold text-indigo-700 mb-4">
              {blog.title}
            </h1>
            <p className="text-sm text-gray-500 mb-4">Published: {blog.date}</p>
            {/* <p className="text-gray-700 leading-relaxed">{blog.content}</p> */}
            <div dangerouslySetInnerHTML={{ __html: blog.content }} className="text-gray-700 leading-relaxed" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetailPage;
