import blogimg1 from "../../assets/homebg.jpg";
import blogimg2 from "../../assets/webdev.jpg";
import blogimg3 from "../../assets/profile background.jpg";
import blogimg4 from "../../assets/iso.png";

export const blogData = [
  {
    id: 1,
    title: "The Complete Guide to Data Entry Work",
    description:
      "Learn everything about data entry work, from essential skills to career growth opportunities.",
    image: blogimg1, // Replace with your image URL
    date: "December 12, 2024",
    content: `
      Data entry work involves inputting, managing, and updating data in systems. It’s not just typing — 
      it includes accuracy, speed, and software expertise. Here, we'll guide you on:
      
      - **Skills Needed**: Attention to detail, proficiency in MS Office, and typing speed.
      - **Tools You Can Use**: Software like Google Sheets, Excel, and CRM tools.
      - **Freelancing Tips**: Create profiles on platforms like Upwork or Fiverr.
      - **Career Opportunities**: Virtual assistant, data analyst assistant, and more.

      Whether you are a student looking for part-time work or a professional exploring freelancing, data entry is a great starting point!
    `,
  },
  {
    id: 2,
    title: "Top Tools for Efficient Data Entry",
    description:
      "Explore the best tools to enhance your productivity and efficiency in data entry work.",
    image: blogimg2,
    date: "December 10, 2024",
    content: `
       <div className="blog-post-container px-6 py-8">
      <h1 className="text-4xl font-bold text-indigo-700 mb-4">
        Mastering the Art of Data Entry Work: A Beginner's Guide to Success
      </h1>
      <p className="text-sm text-gray-500 mb-8">Published: December 2024</p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-3">Introduction: Why Data Entry is More Than Just Typing?</h2>
        <p>
          When you hear “data entry,” you probably think of monotonous typing and endless spreadsheets. But did you know
          that <strong>data entry is the backbone of organized businesses</strong> worldwide? From startups to Fortune 500
          companies, accurate data entry powers decision-making, marketing strategies, and customer satisfaction.
        </p>
        <p>
          Data entry is no longer limited to typing numbers or names. It’s a <strong>combination of precision, software
          knowledge, and organizational skills</strong>, making it an ideal job for detail-oriented individuals. Whether you’re
          a student looking for a part-time gig, a freelancer exploring remote opportunities, or someone aiming to make a
          full-fledged career, data entry can be your gateway to success.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-3">What Exactly is Data Entry Work?</h2>
        <p>
          In simple terms, <strong>data entry involves inputting, organizing, and managing data</strong> using digital tools
          like spreadsheets, databases, or software applications.
        </p>
        <ul className="list-disc list-inside ml-4">
          <li><strong>Typing documents</strong> into digital formats.</li>
          <li><strong>Transcribing handwritten notes</strong> into computer files.</li>
          <li><strong>Updating databases</strong> with customer or product information.</li>
          <li><strong>Cleaning data</strong> by removing duplicates and errors.</li>
          <li><strong>Converting data</strong> from one format to another (PDF to Excel, for instance).</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-3">The Perks of Data Entry Jobs</h2>
        <p>Why are so many people drawn to data entry work? Here are the top reasons:</p>
        <ul className="list-decimal list-inside ml-4">
          <li><strong>Flexibility:</strong> Work from the comfort of your home or a cozy café. Many data entry jobs are remote-friendly.</li>
          <li><strong>Low Barrier to Entry:</strong> You don’t need a fancy degree. Basic typing skills, attention to detail, and familiarity with tools like Excel or Google Sheets can get you started.</li>
          <li><strong>Opportunities for Growth:</strong> Move into specialized fields like data analysis, database management, or AI-powered data labeling.</li>
          <li><strong>Global Demand:</strong> Data entry jobs are available worldwide, catering to various industries.</li>
          <li><strong>Perfect for Multitaskers:</strong> Got a Netflix show running in the background? Data entry tasks can often be light enough to multitask.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-3">What Skills Do You Need?</h2>
        <p>While data entry is beginner-friendly, possessing these skills can make you stand out:</p>
        <ul className="list-disc list-inside ml-4">
          <li><strong>Fast and Accurate Typing:</strong> Aim for a typing speed of at least 50 words per minute with minimal errors.</li>
          <li><strong>Knowledge of Productivity Tools:</strong> Familiarity with Microsoft Office (Excel, Word) and Google Workspace is essential.</li>
          <li><strong>Attention to Detail:</strong> Spotting errors is critical.</li>
          <li><strong>Time Management:</strong> Handle deadlines efficiently.</li>
          <li><strong>Communication Skills:</strong> Clarify tasks and report progress effectively.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-3">Conclusion: Is Data Entry Right for You?</h2>
        <p>
          If you love structure, enjoy working independently, and have an eye for detail, <strong>data entry work can be a fulfilling and
          flexible career option</strong>. With the right skills and attitude, data entry can open doors to exciting opportunities and a steady income.
        </p>
        <p>Whether you're a beginner or an experienced professional, there's always room to grow in this field. Every keystroke counts!</p>
      </section>
    </div>
    `,
  },
  {
    id: 3,
    title: "Freelancing in Data Entry: A Beginner’s Guide",
    description:
      "Step into the freelancing world with our beginner’s guide to data entry jobs.",
    image: blogimg3,
    date: "December 8, 2024",
    content: [
      `
      Freelancing in data entry is a flexible and lucrative career option. Here's how to get started:
      
      - **Create a Portfolio**: Showcase sample work or certifications.
      - **Choose Platforms**: Sign up on freelancing websites like Upwork, Freelancer, or Fiverr.
      - **Set Competitive Rates**: Start low to gain experience and reviews.
      - **Build Relationships**: Communicate effectively with clients and ensure timely delivery.
      
      Data entry freelancing can pave the way for a stable and rewarding career. Start small but dream big!
    `,
    ],
  },
];
