import React, { useState } from "react";
import { loginUser } from "../auth/AuthService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // Initialize navigate hook

  const handleLogin = async () => {
    try {
      const { user, role } = await loginUser(email, password);
      toast.success(`Welcome back! Logged in as ${role} You are in waiting`, { position: "top-right" });
      alert(`Welcome back! Logged in as ${role} You are in waiting`)
      
      if (role === "employer") {
        // Redirect to Employer Dashboard
        toast.success("going to profile")
        navigate("/profile");  // Adjust the route path
      } else if (role === "job_seeker") {
        // Redirect to Job Seeker Dashboard
        navigate("/work"); // Adjust the route path
      }
    } catch (error) {
      toast.error("Error in login. Please check your credentials!", { position: "top-right" });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-blue-500 to-purple-600 p-4">
      <ToastContainer />
      <div className="w-full max-w-sm bg-white rounded-lg shadow-lg p-6 space-y-4">
        <h2 className="text-2xl font-bold text-center text-gray-700">Login</h2>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input-field"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input-field"
        />
        <button
          onClick={handleLogin}
          className="btn-glow w-full bg-gradient-to-r from-purple-500 to-blue-500 text-white py-2 px-4 rounded-md font-semibold"
        >
          Login
        </button>
        <p className="text-sm text-center text-gray-500">
          Don't have an account? <Link to ="/signup" className="text-blue-500 cursor-pointer">Sign up</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
