import React from "react";
import LoadingImg from ".././assets/loading.gif";
function LoadingSpinner() {
  return (
    <div>
      <img src={LoadingImg} alt="Loading" />
    </div>
  );
}

export default LoadingSpinner;
