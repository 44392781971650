import React, { useState, useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaTrashAlt } from "react-icons/fa";
import LoadingSpinner from "../LoadingSpinner";
import "./profile.css"
const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [jobPosts, setJobPosts] = useState([]);
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            setUserData(docSnap.data());
            if (docSnap.data().role === "employer") {
              fetchJobPosts(user.uid);
            }
          } else {
            toast.error("User data not found", { position: "top-right" });
          }
        } catch (error) {
          toast.error("Error fetching user data", { position: "top-right" });
        } finally {
          setLoading(false);
        }
      } else {
        toast.error("User is not authenticated", { position: "top-right" });
        setLoading(false);
      }
    };

    const fetchJobPosts = async (employerId) => {
      try {
        const q = query(
          collection(db, "jobPosts"),
          where("employerId", "==", employerId)
        );
        const querySnapshot = await getDocs(q);
        const jobs = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setJobPosts(jobs);
      } catch (error) {
        toast.error("Error fetching job posts", { position: "top-right" });
      }
    };

    fetchUserData();
  }, [auth.currentUser, db]);

  const handleDeleteJobPost = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this job post?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "jobPosts", id));
        setJobPosts((prev) => prev.filter((job) => job.id !== id));
        toast.success("Job post deleted successfully!", {
          position: "top-right",
        });
      } catch (error) {
        toast.error("Failed to delete the job post. Please try again.", {
          position: "top-right",
        });
      }
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      toast.success("Logout successful!");
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
      toast.error("Error during logout. Please try again.");
    }
  };
  

  const handleCreateJobPost = () => {
    navigate("/create-job-post");
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <LoadingSpinner className="w-16 h-16 text-blue-500 animate-spin" />
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="text-center font-bold text-2xl">
        <Link
          to="/login"
          className="text-center font-bold text-2xl text-blue-600"
        >
          User data not available: Please Login to see Your Profile
        </Link>
      </div>
    );
  }

  return (
    <div
      className="min-h-screen flex flex-col bg-cover bg-center profilebg"
    >
      <div className="flex flex-col md:flex-row md:space-x-4 container mx-auto p-6">
        {/* Sidebar */}
        <div className="bg-white p-6 rounded-lg shadow-xl w-full md:w-1/3">
          <h2 className="text-3xl font-bold text-indigo-700 mb-4">
            {userData.role === "employer" ? "Employer Dashboard" : "Job Seeker Profile"}
          </h2>
          <div className="space-y-3">
            <p>
              <span className="font-bold text-indigo-500">Name:</span>{" "}
              {userData.firstName} {userData.lastName}
            </p>
            <p>
              <span className="font-bold text-indigo-500">Email:</span>{" "}
              {userData.email}
            </p>
            <p>
              <span className="font-bold text-indigo-500">Mobile:</span>{" "}
              {userData.mobileNumber}
            </p>
            <p>
              <span className="font-bold text-indigo-500">Address:</span>{" "}
              {userData.address}
            </p>
          </div>
          <button
            onClick={handleLogout}
            className="w-full bg-red-500 text-white py-2 px-4 rounded-md font-semibold shadow-md mt-4 transform transition duration-300 hover:scale-105 hover:bg-red-600 focus:outline-none"
          >
            Logout
          </button>
        </div>

        {/* Main Content */}
        <div className="bg-white p-6 rounded-lg shadow-xl w-full md:w-2/3">
          {userData.role === "employer" ? (
            <>
              <button
                onClick={handleCreateJobPost}
                className="w-full bg-gradient-to-r from-purple-500 to-blue-500 text-white py-2 px-4 rounded-md font-semibold shadow-md mb-6"
              >
                Create Job Post
              </button>
              <h3 className="text-2xl font-semibold text-indigo-600 mb-3">
                Your Job Posts
              </h3>
              {jobPosts.length > 0 ? (
                jobPosts.map((job) => (
                  <div
                    key={job.id}
                    className="flex items-center justify-between p-4 bg-gray-100 rounded-md shadow-md mb-3"
                  >
                    <div>
                      <h4 className="text-lg font-bold text-gray-800">
                        {job.title}
                      </h4>
                      <p className="text-sm text-gray-600">
                        {job.description}
                      </p>
                    </div>
                    <FaTrashAlt
                      onClick={() => handleDeleteJobPost(job.id)}
                      className="text-red-500 cursor-pointer hover:text-red-700"
                      title="Delete Job Post"
                    />
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No jobs posted yet.</p>
              )}
            </>
          ) : (
            <h3 className="text-2xl font-semibold text-indigo-600">
              Welcome to your profile, {userData.firstName}!
            </h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
