import React, { useState } from "react";
import { signupUser } from "../auth/AuthService";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import { doc, setDoc } from "firebase/firestore"; // Firestore functions
import { db } from "../firebaseConfig"; // Firebase config

const Signup = () => {
  const navigate = useNavigate(); // Initialize navigate hook

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "job_seeker",
    address: "",
    mobileNumber: "",
    alternativeMobileNumber: "",
    pinCode: "",
    highestEducation: "",
    workExperience: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSignup = async () => {
    try {
      // Generate a random waiting number for job seekers
      let waitingNumber = null;
      if (formData.role === "job_seeker") {
        waitingNumber = Math.floor(Math.random() * 900) + 100; // Random number between 100-999
      }
  
      const userData = {
        ...formData,
        waitingNumber, // Add waiting number to user data
      };
  
      // Call your signup service
      await signupUser(userData);
  
      // Save user data to Firestore
      const userDoc = doc(db, "users", userData.email);
      await setDoc(userDoc, userData);

      toast.success("Signup successful!", { position: "top-right" });
      alert("Signup successful!")
      navigate("/login");
    } catch (error) {
      toast.error(error.message, { position: "top-right" });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-blue-500 to-purple-600 p-4">
      <div className="w-full max-w-lg bg-white rounded-lg shadow-xl p-6 space-y-4">
        <h2 className="text-2xl font-bold text-center text-gray-700">Signup</h2>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <input
            name="firstName"
            placeholder="First Name"
            onChange={handleChange}
            className="input-field"
            required
          />
          <input
            name="lastName"
            placeholder="Last Name"
            onChange={handleChange}
            className="input-field"
            required
          />
        </div>
        <input
          name="email"
          type="email"
          placeholder="Email"
          onChange={handleChange}
          className="input-field"
          required
        />
        <input
          name="password"
          type="password"
          placeholder="Password"
          onChange={handleChange}
          className="input-field"
          required
        />
        <select
          name="role"
          value={formData.role}
          onChange={handleChange}
          className="input-field"
          required
        >
          <option value="employer">Employer</option>
          <option value="job_seeker">Job Seeker</option>
        </select>
        <input
          name="address"
          placeholder="Address"
          onChange={handleChange}
          className="input-field"
          required
        />
        <input
          name="mobileNumber"
          placeholder="Mobile Number"
          onChange={handleChange}
          className="input-field"
          required
        />
        <input
          name="alternativeMobileNumber"
          placeholder="Alternative Mobile Number"
          onChange={handleChange}
          className="input-field"
          required
        />
        <input
          name="pinCode"
          placeholder="Pin Code"
          onChange={handleChange}
          className="input-field"
          required
        />
        <input
          name="highestEducation"
          placeholder="Highest Education"
          onChange={handleChange}
          className="input-field"
          required
        />
        <input
          name="workExperience"
          placeholder="Work Experience"
          onChange={handleChange}
          className="input-field"
          required
        />
        <button
          onClick={handleSignup}
          className="btn-glow w-full bg-gradient-to-r from-purple-500 to-blue-500 text-white py-2 px-4 rounded-md"
        >
          Signup
        </button>
        <p className="text-sm text-center text-gray-500">
          Already have an account?{" "}
          <Link to="/login" className="text-blue-500 cursor-pointer">
            Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Signup;
