// src/Navbar.js
import React, { useState } from "react";
import logo from "../../assets/dfehyjw5juj.png";
import { Link } from "react-router-dom";

const Navbar = ({ isLoggedIn, userRole }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-[hsl(238,72%,55%)] p-4 h-24">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <div>
          <img
            src={logo}
            alt="My Logo"
            className="h-[60px] hover:cursor-pointer"
          />
        </div>

        {/* Hamburger icon for mobile */}
        <div className="md:hidden" onClick={() => setIsOpen(!isOpen)}>
          <button className="text-white focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
              ></path>
            </svg>
          </button>
        </div>

        {/* Full Nav Links for larger screens */}
        <div className="hidden md:flex space-x-8 items-center font-bold text-xl mr-4">
          <Link
            to="/"
            className="text-white hover:scale-105 font-sans text-[1.2rem]  font-extrabold"
          >
            Home
          </Link>
          <Link
            to="/about"
            className="text-white hover:scale-105 font-sans text-[1.2rem] font-extrabold"
          >
            About
          </Link>
          <Link
            to="/services"
            className="text-white hover:scale-105 font-sans text-[1.2rem] font-extrabold"
          >
            Services
          </Link>
          <Link
            to="/work"
            className="text-white hover:scale-105 font-sans text-[1.2rem] font-extrabold"
          >
            JOBS
          </Link>
          <Link
            to="/profile"
            className="text-white hover:scale-105 font-sans text-[1.2rem] font-extrabold"
          >
            Profile
          </Link>
          <Link
            to="/blogs"
            className="text-white hover:scale-105 font-sans text-[1.2rem] font-extrabold"
          >
            Blog
          </Link>
          <Link
            to="/training"
            className="text-white hover:scale-105 font-sans text-[1.2rem] font-extrabold"
          >
            Training Program
          </Link>
          <Link
            to="/contact"
            className="text-white hover:scale-105 font-sans text-[1.2rem] font-extrabold"
          >
            Contact
          </Link>
        </div>

        {/* Mobile Nav Links with slide-in effect */}
        <div
          className={`fixed top-0 right-0 h-full w-3/4 bg-[#3032dc] text-white transform transition-transform duration-300 ease-in-out z-50 ${
            isOpen ? "translate-x-0" : "translate-x-full"
          } md:hidden`}
        >
          <div className="flex flex-col space-y-6 p-8 text-xl font-extrabold">
            <Link to="/" className="hover:scale-105" onClick={handleCloseMenu}>
              Home
            </Link>
            <Link
              to="/about"
              className="hover:scale-105"
              onClick={handleCloseMenu}
            >
              About
            </Link>
            <Link
              to="/services"
              className="hover:scale-105"
              onClick={handleCloseMenu}
            >
              Services
            </Link>
            <Link
              to="/work"
              className="text-white hover:scale-105 font-sans text-[1.2rem] font-extrabold"
            >
              JOBS
            </Link>
            <Link
              to="/profile"
              className="text-white hover:scale-105 font-sans text-[1.2rem] font-extrabold"
            >
              Profile
            </Link>
            <Link
              to="/blogs"
              className="text-white hover:scale-105 font-sans text-[1.2rem] font-extrabold"
            >
              Blog
            </Link>
            <Link
              to="/training"
              className="hover:scale-105"
              onClick={handleCloseMenu}
            >
              Training Program
            </Link>
            <Link
              to="/contact"
              className="hover:scale-105"
              onClick={handleCloseMenu}
            >
              Contact
            </Link>
          </div>
        </div>

        {/* Overlay for closing menu when clicking outside */}
        {isOpen && (
          <div
            className="fixed inset-0 bg-black opacity-50 z-40"
            onClick={handleCloseMenu}
          ></div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
