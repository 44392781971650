import React, { useState } from "react";
import { getAuth } from "firebase/auth";
import { db } from "../firebaseConfig"; // Firestore instance
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { toast } from "react-toastify";

const JobPostForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    location: "",
    salary: "",
    companyName: "",
    openings: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      // alert("You must be logged in to post a job!");
      toast.error("You must be logged in to post a job!");
      return;
    }

    try {
      await addDoc(collection(db, "jobPosts"), {
        ...formData,
        employerId: currentUser.uid, // Associate job post with employer
        timestamp: serverTimestamp(),
      });
      // alert("Job post created successfully!");
      toast.success("Job post created successfully!");
      setFormData({ title: "", description: "", location: "", salary: "",companyName: "", openings: "", });
    } catch (error) {
      console.error("Error posting job:", error);
      // alert("Error posting job. Please try again.");
      toast.error("Error posting job. Please try again.");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-lg mx-auto p-4 bg-white shadow-md rounded-md space-y-4"
    >
      <h2 className="text-xl font-bold mb-4">Create a Job Post</h2>
      <input
        type="text"
        placeholder="Job Title"
        value={formData.title}
        onChange={(e) => setFormData({ ...formData, title: e.target.value })}
        className="input-field mb-4"
        required
      />
      <textarea
        placeholder="Job Description"
        value={formData.description}
        onChange={(e) =>
          setFormData({ ...formData, description: e.target.value })
        }
        className="input-field mb-4"
        required
      ></textarea>
      <input
        type="text"
        placeholder="Location"
        value={formData.location}
        onChange={(e) => setFormData({ ...formData, location: e.target.value })}
        className="input-field mb-4"
        required
      />
      <input
        type="text"
        placeholder="Salary"
        value={formData.salary}
        onChange={(e) => setFormData({ ...formData, salary: e.target.value })}
        className="input-field mb-4"
        required
      />
      <input
        type="text"
        placeholder="Company Name"
        value={formData.companyName}
        onChange={(e) =>
          setFormData({ ...formData, companyName: e.target.value })
        }
        className="input-field mb-4"
        required
      />
      <input
        type="text"
        placeholder="openings"
        value={formData.openings}
        onChange={(e) => setFormData({ ...formData, openings: e.target.value })}
        className="input-field mb-4"
        required
      />
      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md"
      >
        Submit
      </button>
    </form>
  );
};

export default JobPostForm;
